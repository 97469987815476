<template>
  <v-dialog v-model="dialog" width="500">
    <template #activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        class="mr-1"
        small
        v-bind="attrs"
        v-on="on"
        @click="openDialog()"
      >
        New 360 View
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span>Add 360 View</span>
      </v-card-title>
      <v-divider />
      <ValidationObserver v-slot="{ invalid }">
        <v-card-text class="pt-5">
          <v-form>
            <ERow>
              <ECol cols="12" class="py-0">
                <Autocomplete
                  v-model="selectedProject"
                  item-value="id"
                  item-text="name"
                  label="Project name"
                  no-filter
                  :cache-items="false"
                  resource="projects"
                />
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Project id"
                  rules="required"
                >
                  <v-text-field
                    v-model="project360.projectId"
                    dense
                    disabled
                    outlined
                    :error-messages="errors"
                    label="Project id"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Integration types"
                  rules="required"
                >
                  <v-select
                    v-model="project360.integrationType"
                    :items="integrationTypes"
                    label="Integration types"
                    dense
                    outlined
                    :error-messages="errors"
                    item-value="value"
                    item-text="name"
                  />
                </ValidationProvider>
              </ECol>
              <ECol
                v-if="project360.integrationType"
                cols="11"
                class="py-0 mx-auto"
              >
                <v-text-field
                  v-model="project360.providerPrefix"
                  dense
                  outlined
                  label="Provider Prefix"
                />
                <v-text-field
                  v-model="project360.providerSuffix"
                  dense
                  outlined
                  label="Provider Suffix"
                />
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Provider ID"
                  rules="required"
                >
                  <v-text-field
                    v-model="project360.providerID"
                    dense
                    outlined
                    :error-messages="errors"
                    label="Provider ID"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="4D timeline"
                  rules="required"
                >
                  <v-select
                    v-model="project360.timeline"
                    :items="yesNoSelect"
                    label="4D timeline"
                    dense
                    outlined
                    :error-messages="errors"
                    item-value="value"
                    item-text="name"
                  />
                </ValidationProvider>
              </ECol>
            </ERow>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Cancel </v-btn>
          <v-btn
            color="primary"
            :loading="isLoading"
            text
            :disabled="invalid"
            @click="add360View()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios"
import Autocomplete from "@evercam/shared/components/Autocomplete"
import { _360_INTEGRATION_TYPE, YES_NO_SELECT } from "@/components/constants"

export default {
  components: {
    Autocomplete,
  },
  props: {
    get360Projects: {
      type: Function,
      default: () => ({}),
    },
  },
  data() {
    return {
      dialog: false,
      selectedProject: null,
      project360: {
        projectId: null,
        integrationType: null,
        timeline: null,
        projectName: null,
        apiId: null,
        apiKey: null,
        providerID: null,
        providerPrefix: "",
        providerSuffix: "",
      },
      integrationTypes: _360_INTEGRATION_TYPE,
      yesNoSelect: YES_NO_SELECT,
      isLoading: false,
    }
  },
  watch: {
    async selectedProject(project) {
      if (project) {
        this.project360.projectId = project.exid
        this.project360.projectName = project.name
      }
    },
  },
  methods: {
    openDialog() {
      this.project360 = {
        projectId: null,
        integrationType: null,
        timeline: null,
        projectName: null,
        apiId: null,
        apiKey: null,
        providerID: null,
        providerPrefix: "",
        providerSuffix: "",
      }
      this.selectedProject = null
    },
    async add360View() {
      try {
        this.isLoading = true
        const project360 = {
          ...this.project360,
          uniqueId: `${this.project360.projectId}${this.project360.integrationType}`,
        }
        let { data } =
          (await axios.get(
            `${this.$config.public.firebaseDbLink}data/360-list.json`
          )) ?? []
        data.unshift(project360)
        await axios.put(
          `${this.$config.public.firebaseDbLink}data/360-list.json`,
          { ...data }
        )
        await axios.put(
          `${this.$config.public.firebaseDbLink}data/projects/360/${this.project360.projectId}.json`,
          project360
        )
        this.get360Projects()
        this.$notifications.success("360 view successfully saved")
      } catch (error) {
        this.$notifications.error({ text: "Failed to delete 360 view!", error })
      } finally {
        this.isLoading = false
        this.dialog = false
      }
    },
  },
}
</script>
