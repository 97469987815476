import { _360_INTEGRATION_TYPE, YES_NO_SELECT } from "@/components/constants"
export default {
  projectName: {
    component: "TextFieldSearchFilter",
    offline: true,
  },
  projectId: {
    component: "TextFieldSearchFilter",
    offline: true,
  },
  integrationType: {
    component: "SelectSearchFilter",
    attributes: {
      items: [{ name: "All", value: null }, ..._360_INTEGRATION_TYPE],
      value: null,
    },
    offline: true,
  },
  timeline: {
    component: "SelectSearchFilter",
    attributes: {
      items: [{ name: "All", value: null }, ...YES_NO_SELECT],
      value: null,
    },
    offline: true,
  },
}
